import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

import { ViewComponentProps } from "core/components/base/ViewComponent"

import { ViewComponent } from "../base/ViewComponent"

interface ScoreBarProps extends ViewComponentProps {
  className?: string
  color?: string
  max?: number
  score?: number
}

class ScoreBarView extends ViewComponent<ScoreBarProps & WithStyles<typeof styles>> {
  render() {
    super.render()

    const { classes, className, max = 0, score = 0 } = this.props
    const bars = []

    for (let i = 0; i < max; i++) {
      // Calculate score bar width in current point range
      // e.g. score of 1.5 should fill 100% of the 1 point bar and 50% of the 2 point bar
      const width = Math.max(0, Math.min(score - i, 1)) * 100 + "%"

      bars.push(
        <div key={i}>
          <div className="scored" style={{ width }} />
        </div>
      )
    }

    return <div className={classNames(className, classes.root)}>{bars}</div>
  }
}

const styles = () =>
  createStyles<any, ScoreBarProps>({
    root: props => ({
      borderRadius: "6px",
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",

      "& > div": {
        borderBottom: `6px solid ${ViewComponent.theme.colors.disabled.toString()}`,
        flex: 1,
        height: 0
      },
      "& > div:not(:last-child)": {
        marginRight: "1px"
      },
      "& .scored": {
        borderBottom: `6px solid ${props.color || ViewComponent.theme.colors.secondary.toString()}`
      }
    })
  })

export default withStyles<any, any, ScoreBarProps>(styles)(ScoreBarView)
