import Typography from "@material-ui/core/Typography"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import { TranslatedString } from "core/modules/state/model/Model"
import { MOBILE_BREAKPOINT } from "lib/ui/compatibility/Browsers"

import { getAnswerPoints } from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../base/ViewComponent"
import ScoreBarView from "./ScoreBarView"

interface Option {
  id?: string | number
  points?: number
  title?: TranslatedString
}

interface Answer {
  category?: TranslatedString
  options?: Option[]
  title?: TranslatedString
}

interface Props extends ViewComponentProps {
  answer: Answer
  averageScore?: number
  maxScore?: number
  previousAverageScore?: number
  previousAnswer?: Answer
  showCategory?: boolean
}

class AnswerView extends ViewComponent<Props & WithStyles<typeof styles>> {
  get componentName() {
    return ["reports", "AnswerView"]
  }

  colors = [
    this.theme.colors.report.report_need_extra_focus.toString(),
    this.theme.colors.report.report_need_attention.toString(),
    this.theme.colors.report.report_neutral.toString(),
    this.theme.colors.report.report_well_done.toString(),
    this.theme.colors.report.report_excellent.toString()
  ]

  render() {
    super.render()

    const { answer, classes, showCategory } = this.props

    return (
      <div className={classes.root}>
        <Typography>
          {showCategory ? (
            <>
              <strong className={classes.category}>{this.txt(answer.category)}</strong>
              <em>{this.txt(answer.title)}</em>
            </>
          ) : (
            this.txt(answer.title)
          )}
        </Typography>
        {this.renderOptions()}
      </div>
    )
  }

  previous_points() {
    const { previousAnswer, previousAverageScore } = this.props
    if (previousAverageScore) {
      return previousAverageScore
    } else if (previousAnswer) {
      return getAnswerPoints(previousAnswer)
    }
    return 0
  }

  private renderOptions() {
    const { answer, averageScore, previousAnswer, classes, maxScore = 5 } = this.props

    const points = averageScore ?? getAnswerPoints(answer)
    const color = this.colors[Math.max(0, Math.min(this.colors.length, Math.ceil(points)) - 1)]
    const previous_color = this.colors[Math.max(0, Math.min(this.colors.length, Math.ceil(this.previous_points())) - 1)]

    return (
      <div className={classes.score}>
        <ScoreBarView className={classes.bar} color={color} max={maxScore} score={points} />
        {averageScore !== undefined
          ? this.renderAverageScore(averageScore)
          : answer?.options?.map(this.renderOptionLabel)}
        {previousAnswer && (
          <div className={classes.previousScoreContainer}>
            <Typography color="primary" align="right">
              {this.txt("previous_score_title")}
            </Typography>
            <ScoreBarView
              className={classes.previous_bar}
              color={previous_color}
              max={maxScore}
              score={this.previous_points()}
            />
            {averageScore !== undefined
              ? this.renderAverageScore(this.previous_points())
              : previousAnswer?.options?.map(this.renderOptionLabel)}
          </div>
        )}
      </div>
    )
  }

  private renderAverageScore = score => (
    <Typography color="textSecondary" align="right">
      {this.txt("average_score", [score.toPrecision(2)])}
    </Typography>
  )

  private renderOptionLabel = (option: Option) => (
    <Typography key={option.id} color="primary" align="right">
      {this.txt(option.title)}
    </Typography>
  )
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },

    bar: {
      marginBottom: spacing(1),
      marginTop: "5px",
      width: "140px"
    },

    previous_bar: {
      marginBottom: spacing(1),
      marginTop: "5px",
      width: "140px"
    },

    previousScoreContainer: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
      opacity: "0.6"
    },

    category: {
      color: ViewComponent.theme.colors.deEmphasizedColor.toString(),
      display: "block"
    },

    score: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "column",
      maxWidth: "200px"
    },

    [`@media only screen and (max-width: ${MOBILE_BREAKPOINT}px)`]: {
      bar: { width: "100px" },
      score: { maxWidth: "160px" }
    }
  })

export default withStyles<any, any, Props>(styles)(AnswerView)
