import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import { TriangleDirection } from "../symbols"
import Triangle from "../symbols/Triangle"
import Typography from "../typography/Typography"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import { ViewComponent } from "../base/ViewComponent"

interface GASScaleViewProps extends ViewComponentProps {
  answerDistributions?: number[]
  max?: number
  color?: string
  score?: number
}

class GASScaleView extends ViewComponent<GASScaleViewProps & WithStyles<typeof styles>> {
  get componentName() {
    return ["reports", "GASScaleView"]
  }

  render() {
    super.render()

    const { answerDistributions, classes, max = 2, score } = this.props
    const deltaTriangleSize = 0.6
    const triangleDirection: TriangleDirection = "down"

    const bars = []
    const indicators = []
    const titles = []
    const answerDistributionTitles = []
    for (let i = -2; i <= max; i++) {
      // Calculate score bar width in current point range
      // e.g. score of 1.5 should fill 100% of the 1 point bar and 50% of the 2 point bar
      let title = ""
      switch (i) {
        case -2:
          title = this.txt("much_worse_than_expected_title")
          break

        case -1:
          title = this.txt("slightly_worse_than_expected_title")
          break

        case 0:
          title = this.txt("as_expected_title")
          break

        case 1:
          title = this.txt("slightly_better_than_expected_title")
          break

        case 2:
          title = this.txt("much_better_than_expected_title")
          break

        default:
          break
      }

      const className = score === i ? "score" + score : ""

      bars.push(
        <div key={i}>
          <div className={className}>
            <Typography paragraph variant="description">
              {i}
            </Typography>
          </div>
        </div>
      )
      titles.push(
        <div key={`title${i}`}>
          <Typography paragraph>{title}</Typography>
        </div>
      )

      if (score === i) {
        indicators.push(
          <div key={`indicator${i}`}>
            <Triangle
              width={deltaTriangleSize}
              height={deltaTriangleSize}
              direction={triangleDirection}
              color={ViewComponent.theme.colors.disabled.toString()}
            />
          </div>
        )
      } else {
        indicators.push(<div key={`indicator${i}`} />)
      }

      if (answerDistributions) {
        answerDistributionTitles.push(
          <div key={`distribution${i + 2}`}>
            <Typography paragraph>{answerDistributions[i + 2]}%</Typography>
          </div>
        )
      }
    }

    return (
      <>
        <div className={classes.distributionTitlesRoot}>{answerDistributionTitles}</div>
        <div className={classes.indicatorContainer}>{indicators}</div>
        <div className={classes.root}>{bars}</div>
        <div className={classes.titlesRoot}>{titles}</div>
      </>
    )
  }
}

const styles = () =>
  createStyles({
    indicatorContainer: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        flex: 1
      },
      "& > div > div": {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "0.5rem"
      }
    },
    distributionTitlesRoot: {
      display: "flex",
      flexDirection: "row",
      height: "2rem",
      "& > div": {
        flex: 1
      },
      "& > div > p": {
        margin: "0",
        textAlign: "center"
      }
    },
    titlesRoot: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        flex: 1
      },
      "& > div > p": {
        textAlign: "center"
      }
    },
    root: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        backgroundColor: ViewComponent.theme.colors.disabled.toString(),
        flex: 1,
        height: "2rem",
        textAlign: "center"
      },
      "& > div > div": {
        height: "2rem"
      },
      "& > div > div >p": {
        margin: 0
      },
      "& > div:last-child": {
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px"
      },
      "& > div:not(:last-child)": {
        marginRight: "1px"
      },
      "& .score-2": {
        borderTopLeftRadius: "6px",
        borderBottomLeftRadius: "6px",
        backgroundColor: ViewComponent.theme.colors.report.report_need_extra_focus.toString()
      },
      "& .score-1": {
        backgroundColor: ViewComponent.theme.colors.report.report_need_attention.toString()
      },
      "& .score0": {
        backgroundColor: ViewComponent.theme.colors.report.neutral.toString()
      },
      "& .score1": {
        backgroundColor: ViewComponent.theme.colors.report.report_well_done.toString()
      },
      "& .score2": {
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        backgroundColor: ViewComponent.theme.colors.report.report_excellent.toString()
      },
      "& > div:first-child": {
        borderTopLeftRadius: "6px",
        borderBottomLeftRadius: "6px"
      },
      "& .valueTitle": {
        textAlign: "center"
      }
    },
    secondTitle: {
      backgroundColor: "green",
      display: "grid",
      flexDirection: "row",
      textAlign: "center",
      "& > div:nth-child(2)": {
        left: "50%"
      }
    }
  })

export default withStyles<any, any, GASScaleViewProps>(styles)(GASScaleView)
